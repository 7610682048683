@font-face {
  font-family: Poppins;
  src: url(./fonts/Poppins-Regular.ttf);
}

body {
  color: rgba(77,76,77,255);
  /*background-color: black;*/
  font-family: Poppins, Circular, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  user-select: none;
  font-weight: normal;
}

h1 {
  font-size: 1.4em;
}

h2 {
  font-size: 1.2em;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: rgb(0, 0, 0);
  -webkit-box-shadow: 0 0 0 1000px rgb(255, 255, 255) inset;
  transition: background-color 5000s ease-in-out 0s;
}

#root {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
}

@keyframes hideshow-1 {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes hideshow-2 {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes hideshow-3 {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.signal-ring-1 {
  opacity: 0;
  animation: hideshow-1 2s ease infinite;
}

.signal-ring-2 {
  opacity: 0;
  animation: hideshow-2 2s ease infinite;
}

.signal-ring-3 {
  opacity: 0;
  animation: hideshow-3 2s ease infinite;
}

.MuiSpeedDial-fab {
  background-color: transparent;
}

.customInput {
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid rgba(250,194,3, 0.5);
  color: rgb(0,0,0);
  background-color: transparent;
  font-size: 1.4rem;
  margin: 0.4rem 0.6rem;
}

.customInput:hover {
  border: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.6);
}

.customInput:active {
  border: 0;
  border-bottom: 1px solid rgba(250,194,3, 0.5);
  outline: rgb(40, 40, 40);
}

.customInput:focus {
  border: 0;
  border-bottom: 1px solid rgba(250,194,3, 0.5);
  outline: none;
}

.customInputDivWrapper::after {
  content: '€';
}